/** 
 * @Desc: 首页
 */
<template>
  <div
    class="home-wrapper"
    :class="{'home-wrapper1':styleShow}"
    :style="'background-image: url('+ bgImage +'); background-color:'+ bgColor +';'"
    @mouseup="_homeMouseUp"
  >
    <TopBar ref="topBar" :component="component"/>
    <!-- <div class="home-center-content" :style="'background-image: url('+ bgBottom +')'"> -->
    <div class="home-center-content">
      <NoAuthority v-if="noAuthority"/>
      <ServerError v-else-if="serverError"/>
      <router-view v-else-if="component !== 'NotFound'"></router-view>
      <slot name="error-page"></slot>
      <img class="bottom_Bg" :src="bgBottom" alt="">
    </div>
    <div
      v-if="reversionShow"
      class="reversion-box font-family-normal"
    >前端版本信息：{{ versionMsg }} &nbsp;&nbsp;&nbsp;&nbsp;后端版本信息：{{ version && version.version }}</div>
  </div>
</template>
<script>
import bus from "utils/bus";
import TopBar from "components/top-bar";
import NoAuthority from "components/errorPage/403.vue";
import ServerError from "components/errorPage/500.vue";
import { mapState } from "vuex";
import { versionMessage } from "config/config";
import { localStorageUtil, debounce, GetPageType } from "utils/util";

export default {
  components: {
    TopBar,
    NoAuthority,
    ServerError
  },
  props: {
    component: {
      type: String
    }
  },
  inject: ["reload"],
  data() {
    return {
      reversionShow: false,
      versionMsg: versionMessage,
      reVersion: "",
      imgData: {
        home: {
          bg: require("../../assets/new_ui/new_home_bg-980.png"),
          // bg: require("../../assets/home_bg.png"),
          bg_color: "#76d76e",
          bg_bottom: require("../../assets/new_ui/bottom_bg.png"),
          bg_bottom_h: 213,
          backSize: true
        },
        "12156": {
          // bg: require("../../assets/1_bg.png"),
          bg: require("../../assets/new_ui/new_1_bg-980.png"),

          bg_color: "#f9a9a5",
          bg_bottom: require("../../assets/new_ui/bg_bottom.png"),
          bg_bottom_h: 223,
          backSize: false
        },
        "12158": {
          bg: require("../../assets/new_ui/new_2_bg-980.png"),
          bg_color: "#c1a5fd",
          // bg_bottom: require("../../assets/new_ui/new_2_bg.png"),
          bg_bottom: require("../../assets/new_ui/bg_bottom.png"),

          bg_bottom_h: 219,
          backSize: false
        },
        "12157": {
          bg: require("../../assets/new_ui/new_3_bg-980.png"),
          bg_color: "#8ec4fe",
          // bg_bottom: require("../../assets/new_ui/3_bg_bottom.png"),
          bg_bottom: require("../../assets/new_ui/bg_bottom.png"),

          bg_bottom_h: 219,
          backSize: false
        },
        "12155": {
          bg: require("../../assets/new_ui/new_4_bg-980.png"),
          // bg: require("../../assets/4_bg.png"),
          bg_color: "#fac26a",
          // bg_bottom: require("../../assets/new_ui/new_4_bg.png"),
          bg_bottom: require("../../assets/new_ui/bg_bottom.png"),

          bg_bottom_h: 168,
          backSize: false
        },
        "12159": {
          bg: require("../../assets/new_ui/new_5_bg-980.png"),
          bg_color: "#74d77c",
          // bg_bottom: require("../../assets/new_ui/new_5_bg.png"),
          bg_bottom: require("../../assets/new_ui/bg_bottom.png"),

          bg_bottom_h: 174,
          backSize: false
        },
        "personal-center": {
          bg: require("../../assets/new_ui/personal_bg-980.png"),
          bg_color: "#76d76e",
          bg_bottom: require("../../assets/new_ui/bottom_bg.png"),
          bg_bottom_h: 123,
          backSize: true
        }
      },
      pageType: "home",
      isPlayPage: false,
      play_bg: {
        bg: require("../../assets/new_ui/play_bg.png"),
        bg_bottom: require("../../assets/new_ui/bottom_bg.png")
      }
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.query.reload && !localStorageUtil.getItem("isReload")) {
          localStorageUtil.setItem("isReload", true);
          localStorageUtil.removeItem("isLogin");
          this.reload();
        } else if (localStorageUtil.getItem("isReload")) {
          localStorageUtil.removeItem("isReload");
        }
        this._getPageType(val);
      }
    }
  },
  computed: {
    ...mapState([
      "accountInfo",
      "isLogin",
      "noAuthority",
      "serverError",
      "version"
    ]),
    bgImage() {
      if (
        this.noAuthority ||
        this.serverError ||
        this.component === "NotFound"
      ) {
        return this.imgData["personal-center"].bg;
      } else if (this.isPlayPage) {
        return this.play_bg.bg;
      } else {
        return this.imgData[this.pageType].bg;
      }
    },
    bgColor() {
      if (
        this.noAuthority ||
        this.serverError ||
        this.component === "NotFound"
      ) {
        return this.imgData["personal-center"].bg_color;
      } else if (this.isPlayPage) {
        return "#6BD668";
      } else {
        return this.imgData[this.pageType].bg_color;
      }
    },
    bgBottom() {
      if (
        this.noAuthority ||
        this.serverError ||
        this.isPlayPage ||
        this.component === "NotFound"
      ) {
        return "";
      } else if (this.isPlayPage) {
        return this.play_bg.bg_bottom;
      } else {
        return this.imgData[this.pageType].bg_bottom;
      }
    },
    styleShow() {
      if (this.isPlayPage) {
        return true;
      } else {
        return this.imgData[this.pageType].backSize;
      }
    }
  },
  methods: {
    _getPageType(val) {
      let _last = this.pageType;
      this.pageType = GetPageType(val);
      let _l = val.path.split("/");
      if (_l[2] && _l[2] === "play") {
        this.isPlayPage = true;
      } else {
        this.isPlayPage = false;
      }
      this.$nextTick(() => {
        if (this.$refs.topBar) {
          this.$refs.topBar._pageType(this.pageType, _last);
        }
      });

      localStorageUtil.setItem("pageType", this.pageType);
    },
    _homeMouseUp(e) {
      let _class = $(e.target).attr("class");
      if (_class && _class.indexOf("desc-ide") !== -1) {
        return;
      }
      $("#resourceContent .desc-tip").each(function() {
        $(this).css({ display: "none" });
      });
    },
    /**
     * 判断版本信息的显示网址
     */
    _checkHost: function(params) {
      this.reversionShow = location.host.indexOf("net") !== -1;
    }
  },
  created() {
    this._checkHost();
  },
  mounted() {
    bus.$on("Reload", this.reload);
  }
};
</script>
<style lang="less">
.home-wrapper1 {
  // background-size: 100% 100%;
}
.home-wrapper {
  // width: 1920px;
  // height: 1080px;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    // display: none;
  }
  .home-center-content {
    width: 100%;
    min-height: 100vh;
    // padding-top: 78px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    overflow: auto;
    .page-wrapper {
      // min-height: calc(100vh - 78px - 100px);
      // width: 1200px;
      width: 1100px;
      margin: 0 auto;
      // padding-bottom: 140px;
    }
    .resource-content {
      padding: 0;
    }
  }
  .reversion-box {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #000;
    margin-left: 20px;
    z-index: 999;
    white-space: nowrap;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }
}
::-webkit-scrollbar {
  display: none;
}

.bottom_Bg {
  width: 99.15%;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
//
</style>